<template>
    <div class="row">
        <div class="col-lg-12 col-xl-6 order-lg-3 order-xl-2">
            <edutalk-information :confirming="true" v-if="active.information"></edutalk-information>
            <edutalk-identity v-if="active.identity"></edutalk-identity>
            <edutalk-password v-if="active.changePassword"></edutalk-password>
        </div>
        <div class="col-lg-6 col-xl-4 order-lg-2 order-xl-3">
            <edutalk-bank v-if="active.information"></edutalk-bank>
        </div>
        <!--eslint-disable-->
        <edutalk-modal :closeOnClickModal="true" @closed="closed" title="Thay đổi ảnh đại diện" :visible.sync="dialogFormVisible">
            <div class="row">
                <div class="col-lg-12 justify-content-edutalk">
                    <div class="edutalk-croper">
                        <div class="img-cropper">
                            <cropper
                                    ref="cropper"
                                    class="cropper"
                                    :src="image.src"
                                    @change="onChange"
                                    stencil-component="circle-stencil"
                                    :stencil-props="{
                                        aspectRatio: 1,
                                    }"
                                    :debounce="false"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Hủy</el-button>
                <el-button type="primary" class="btn-primary" @click="cropImage">Xác nhận</el-button>
            </span>
        </edutalk-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import EdutalkPassword from "@/views/pages/user/edutalk-password";
    import EdutalkIdentity from "@/views/pages/user/edutalk-identity";
    import EdutalkInformation from "@/views/pages/user/edutalk-information";
    import EdutalkBank from "@/views/pages/user/edutalk-brank";
    import { Cropper } from 'vue-advanced-cropper'
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    import 'vue-advanced-cropper/dist/style.css';
    import 'vue-advanced-cropper/dist/theme.classic.css';

    import {mapGetters} from "vuex";
    import {USER_UPLOAD_IMAGE} from "@/core/services/store/profile.module";

    export default {
        name: "EdutalkProfile",
        components: {
            EdutalkPassword,
            EdutalkIdentity,
            EdutalkInformation,
            EdutalkBank,
            Cropper,
        },
        data() {
            return {
                profile: {
                    image: ''
                },
                active: {
                    information: true,
                    identity: false,
                    changePassword: false,
                },
                dialogFormVisible: false,
                image: {
                    src: null,
                    type: null,
                },
                event: '',
                result: {
                    coordinates: null,
                    image: null
                },
                selected: [],
                options: [
                    {name: 'Nam', id: 1},
                    {name: 'Nữ', id: 0},
                ],
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Thông tin tài khoản", icon: 'far fa-home'}
            ]);
            this.profile.image = this.currentUser.image
        },
        methods: {
            changeActive(tab) {
                _.mapKeysDeep(this.active, (value, key) => {
                    return this.active[key] = key === tab;
                });
            },
            onChange({ coordinates, image }) {
                this.result = { coordinates,  image };
            },
            cropImage() {
                let vm = this;
                const { canvas } = this.$refs.cropper.getResult();
                this.profile.image = canvas.toDataURL();
                if (canvas) {
                    const form = new FormData();
                    canvas.toBlob(blob => {
                        form.append('file', blob);
                        form.append('id', vm.currentUser.profile_id);
                        this.$store.dispatch(USER_UPLOAD_IMAGE, form)
                            .then((response) => {
                                vm.$message.success(response.message);
                            }).catch((error) => {
                            vm.$message.warning(error.message);
                        });
                        this.dialogFormVisible = false;
                    }, 'image/jpeg',0.8);
                }
            },
            reset() {
                this.image = {
                    src: null,
                    type: null,
                };
            },
            closed () {
                this.$refs.input.value = '';
            },
            loadImage(event) {
                const {files} = event.target;
                // Ensure that you have a file before attempting to read it
                if (files && files[0]) {
                    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                    if (this.image.src) {
                        URL.revokeObjectURL(this.image.src)
                    }
                    // 2. Create the blob link to the file to optimize performance:
                    const blob = URL.createObjectURL(files[0]);

                    // Create a new FileReader to read this image binary data
                    const reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                        this.image = {
                            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                            src: blob,
                            // Determine the image type to preserve it during the extracting the image from canvas:
                            type: getMimeType(e.target.result, files[0].type),
                        };
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsArrayBuffer(files[0]);
                }
                this.dialogFormVisible = true;
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

    // This function is used to detect the actual image type,
    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }
</script>
