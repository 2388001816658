<template>
    <ValidationObserver ref="banks" v-slot="{ handleSubmit }">
        <div class="card mb-g show">
        <div class="row row-grid no-gutters dropdown-menu-animated">
            <div class="col-12">
                <div class="p-3">
                    <h2 class="mb-0 fs-xl header_bank">
                        Tài khoản ngân hàng
                        <p> {{ updated_at ? 'Cập nhật lần cuối lúc: ' + updated_at : ''}} </p>
                    </h2>
                </div>
            </div>
            <div class="col-12">
                <div class="p-3">
                    <form @submit.prevent="handleSubmit(summit)" autocomplete="off">
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <label class="form-label">Chủ tài khoản</label>
                                <ValidationProvider vid="owner_name" name="Họ tên ngân hàng" rules="required" v-slot="{ errors, classes }">
                                    <div class="input-group">
                                        <input type="text" class="form-control" :class="classes" v-model="userBank.owner_name" placeholder="Đầy đủ họ tên, không dấu" autocomplete="false">
                                        <div class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="far fa-exclamation-triangle color-danger-900"></i> Tài khoản ngân hàng nhập phải là chính chủ </span>
                                        </div>
                                        <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <label class="form-label">Chọn ngân hàng</label>
                                <ValidationProvider vid="ngan_hang" name="Ngân hàng" rules="required" v-slot="{ errors, classes }">
                                    <edutalk-select v-model="userBank.ngan_hang"  @change="changeBank" class="w-100" :class="classes" clearable filterable placeholder="Chọn ngân hàng">
                                        <edutalk-option
                                                v-for="item in banks"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                        >
                                        </edutalk-option>
                                    </edutalk-select>
                                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                        {{ errors[0] }}
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div v-if="hideProvince" class="col-md-6 mb-3">
                                <label class="form-label">Tỉnh thành</label>
                                <ValidationProvider vid="province" name="Tỉnh thành" :rules="hideProvince ? 'required' : ''" v-slot="{ errors , classes }">
                                    <edutalk-select v-model="userBank.province"  :class="classes" @change="changeProvince" class="w-100" clearable filterable placeholder="Chọn tỉnh thành">
                                        <edutalk-option
                                                v-for="item in provinces"
                                                :key="item"
                                                :label="item"
                                                :value="item">
                                        </edutalk-option>
                                    </edutalk-select>
                                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                        {{ errors[0] }}
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div v-if="hideProvince" class="col-md-6 mb-3">
                                <label class="form-label">Chi nhánh</label>
                                <ValidationProvider vid="chi_nhanh" name="Tỉnh thành" :rules="hideProvince ? 'required' : ''" v-slot="{ errors, classes }">
                                    <edutalk-select v-model="userBank.chi_nhanh" class="w-100" clearable filterable :class="classes" placeholder="Chọn chi nhánh">
                                        <edutalk-option
                                                v-for="item in agency"
                                                :key="item"
                                                :label="item"
                                                :value="item">
                                        </edutalk-option>
                                    </edutalk-select>
                                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                        {{ errors[0] }}
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label class="form-label">Số tài khoản</label>
                                <ValidationProvider vid="stk" name="Số tài khoản" rules="required" v-slot="{ errors, classes }">
                                    <input type="text" class="form-control" placeholder="Số tài khoản ngân hàng" :class="classes" v-model="userBank.stk"  autocomplete="false">
                                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                        {{ errors[0] }}
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <button :disabled="loading" class="btn btn-primary waves-effect waves-themed"> <i v-if="loading" class="el-icon-loading"></i> Cập nhật thông tin ngân hàng </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </ValidationObserver>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import provinces from '@/core/config/provinces';
    import agency from '@/core/config/agency';
    import banks from '@/core/config/banks';
    import {USER_UPDATE_BANK} from "@/core/services/store/profile.module";
    export default {
        name: "EdutalkBank",
        data() {
            return {
              userBank: {
                    chi_nhanh: "",
                    ngan_hang: "",
                    stk: "",
                    province: "",
                    owner_name: "",
                    updated_at: "",
                },
                provinces: provinces,
                agency: [],
                banks: banks,
                hideProvince: false,
                loading: false,
                updated_at: ''
            }
        },
        watch : {
            'currentUser.bank_accounts.0': {
                handler(newValue, oldValue) {
                    if (newValue || oldValue) {
                        if (newValue?.updated_at !== oldValue?.updated_at) {
                            // Perform your data modification here
                            this.updated_at = this.$moment(newValue.updated_at, 'YYYY-MM-DD HH:mm:ss').fromNow();// Example modification: get the length of bank_accounts + 1
                        }
                    } else {
                        this.userBank = {
                            chi_nhanh: "",
                            ngan_hang: "",
                            stk: "",
                            province: "",
                            owner_name: "",
                            updated_at: "",
                        }
                    }

                },
            }
        },
        mounted() {
         this.getBank();
        },
        methods: {
            getBank(){
              if (this.currentUser?.bank_accounts[0]){
                this.userBank = this.currentUser.bank_accounts[0];
                  this.updated_at = this.$moment(this.userBank.updated_at, 'YYYY-MM-DD HH:mm:ss').fromNow();
                if (this.userBank.ngan_hang === 'Agribank') {
                  this.hideProvince = true;
                }
              }
            },
            changeBank() {
                this.hideProvince = false;
                if (this.userBank.ngan_hang === 'Agribank') {
                    this.hideProvince = true;
                    this.userBank.province = '';
                    this.userBank.chi_nhanh = '';
                }
            },
            changeProvince() {
                this.agency = agency[this.userBank.province];
                this.userBank.chi_nhanh = '';
            },
            summit() {
                let date = new Date();
                let firstDay = new Date(date.getFullYear(), date.getMonth(), 5, 18);
                let lastDay = new Date(date.getFullYear(), date.getMonth(), 12, 9);
                if (date >= firstDay && date <= lastDay) {
                    //`Đã hết thời hạn cập nhật thông tin tài khoản ngân hàng để nhận thu nhập tháng ${date.getMonth()}/${date.getFullYear()}. Thông tin này sẽ được sử dụng để trả thu nhập tháng kế tiếp.`
                    const h = this.$createElement;
                    this.$msgbox({
                        title: 'Thông báo',
                        message: h('p', null, [
                            h('span', null, 'Đã hết thời hạn cập nhật thông tin tài khoản ngân hàng để nhận thu nhập tháng '),
                            h('i', { style: 'color: teal' }, `${date.getMonth()}/${date.getFullYear()}.`),
                            h('br', null, null),
                            h('span', null, `Thông tin này sẽ được sử dụng để trả thu nhập tháng kế tiếp.`)
                        ]),
                        showCancelButton: true,
                        showClose: false,
                        confirmButtonText: 'Tiếp tục',
                        cancelButtonText: 'Hủy',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.storeBank();
                            }
                            done();
                        }
                    })
                } else  {
                    this.storeBank()
                }
            },
            storeBank() {
                let params = {
                    agency_bank: this.userBank.chi_nhanh,
                    bank_name: this.userBank.ngan_hang,
                    bank_number: this.userBank.stk,
                    province_bank: this.userBank.province,
                    owner_card_bank: this.userBank.owner_name,
                };
                this.loading = true;
                this.$store.dispatch(USER_UPDATE_BANK, params)
                    .then((res) => {
                        this.userBank.updated_at = res.data.last_update_bank;
                        this.$message({
                            type: 'success',
                            message: 'Cập nhật thông tin ngân hàng thành công!',
                            showClose: true
                        });
                        this.loading = false;
                        this.$refs.banks.reset();
                    });
                if (this.errors) {
                    this.$refs.banks.setErrors(this.errors);
                }
            }
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            ...mapGetters(['currentUser'])
        }
    };
</script>
